import 'core-js';
import { UiProvider } from '@coconut-software/ui';
import * as Sentry from '@sentry/browser';
import { thirdPartyErrorFilterIntegration } from '@sentry/browser';
import { createBrowserHistory } from 'history';
import nested from 'jss-plugin-nested';
import React from 'react';
import { render } from 'react-dom';
import { jss, ThemeProvider } from 'react-jss';
import { Router } from 'react-router-dom';
import reset from 'reset-jss';
import { CxSnippetProvider } from '../shared/contexts/CxSnippetContext';
import { GoogleMapsApiProvider } from '../shared/contexts/GoogleMapsApiContext';
import { ResetProvider } from '../shared/contexts/ResetContext';
import { VendorColorProvider } from '../shared/contexts/VendorColorContext';
import App from './components/App';
import { AppointmentProvider } from './contexts/AppointmentContext';
import { CountriesProvider } from './contexts/CountriesContext';
import { CountryProvider } from './contexts/CountryContext';
import { DetectedTimezoneProvider } from './contexts/DetectedTimezoneContext';
import { FeatureProvider } from './contexts/FeatureContext';
import { HeaderProvider } from './contexts/HeaderContext';
import { LocaleProvider } from './contexts/LocaleContext';
import { PositionProvider } from './contexts/PositionContext';
import { SelectionProvider } from './contexts/SelectionContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { SnackProvider } from './contexts/SnackContext';
import { StepProvider } from './contexts/StepContext';
import { TimezoneProvider } from './contexts/TimezoneContext';
import { TimezonesProvider } from './contexts/TimezonesContext';
import { UsersProvider } from './contexts/UsersContext';
import { ViewModeProvider } from './contexts/ViewModeContext';
import Theme from './helpers/Theme';

const history = createBrowserHistory();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: window.env,
    dsn: 'https://147bc8676ca64a9eb4258c6bb8e4406e@o206331.ingest.sentry.io/4390617',
    release: window.state.build,
    integrations: [
      thirdPartyErrorFilterIntegration({
        filterKeys: ['frontend-application-key'],
        behaviour: 'apply-tag-if-exclusively-contains-third-party-frames',
      }),
    ],
  });

  Sentry.setUser({ id: window.state.meta.booker });
}

jss.use(nested());
jss.createStyleSheet(reset).attach();

const application = (
  <Router history={history}>
    <GoogleMapsApiProvider
      apiKey={window.state.env.GOOGLEMAPS_API_KEY}
      countries={window.state.countries}
    >
      <ViewModeProvider>
        <SnackProvider>
          <LocaleProvider>
            <UiProvider
              primary={window.state.theme?.primary}
              secondary={window.state.theme?.secondary}
            >
              <SettingsProvider>
                <AppointmentProvider>
                  <SelectionProvider>
                    <DetectedTimezoneProvider>
                      <CountriesProvider>
                        <CountryProvider>
                          <TimezonesProvider>
                            <TimezoneProvider>
                              <StepProvider>
                                <HeaderProvider>
                                  <ThemeProvider theme={Theme.create()}>
                                    <VendorColorProvider>
                                      <UsersProvider>
                                        <FeatureProvider>
                                          <CxSnippetProvider>
                                            <PositionProvider>
                                              <ResetProvider>
                                                <App />
                                              </ResetProvider>
                                            </PositionProvider>
                                          </CxSnippetProvider>
                                        </FeatureProvider>
                                      </UsersProvider>
                                    </VendorColorProvider>
                                  </ThemeProvider>
                                </HeaderProvider>
                              </StepProvider>
                            </TimezoneProvider>
                          </TimezonesProvider>
                        </CountryProvider>
                      </CountriesProvider>
                    </DetectedTimezoneProvider>
                  </SelectionProvider>
                </AppointmentProvider>
              </SettingsProvider>
            </UiProvider>
          </LocaleProvider>
        </SnackProvider>
      </ViewModeProvider>
    </GoogleMapsApiProvider>
  </Router>
);

render(application, document.getElementById('app'));
